import ContactUs from './form';
import './grid.css';
import forside from '../images/Forside1.jpg';

// Function will return a grid of divs with information 
function InformationGrid(){
    
    return(
            
        <div className="grid">
        
            <div className='rowEntry'>
                <div className="headerText">
                    Velkommen til Holmestrand Bodhotell
                </div>
            </div>

            <div className='rowEntry'> 
                <div className="textBox">
                    <p>
                        Holmestrand Bodhotell er et lite prosjekt som tilbyr utleie av boder til privatpersoner og bedrifter.
                        Vi har som mål å tilby enkel og rimelig lagringsplass for alle som trenger det.
                    </p>
                </div>
            </div>

            <img src={forside} alt="Forside"/>

            <div className='rowEntry'>
                <div className="textBoxPcOnly">
                    <p>
                            Vi tilbyr lagerplasser med konkurransedyktige priser. 
                    </p>

                    <p>
                        Lageret befinner seg på Benstrudveien 18 i Holmestrand og er lett tilgjenelig for både nærliggende boligfelt og andre.
                        Lagerbygget er moderne og har både heis og trapp for flytting av små og store gjenstander.
                        I tillegg er uteplassen stor og enkel å manøvrere i med både personbil, varebil, eller lastebil.
                    </p>
                </div>

                <div className="mapBox">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2026.8172977128716!2d10.319848277175552!3d59.46947119979514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464135000379f113%3A0x45c134878431cfdd!2sHolmestrand%20Bodhotell!5e0!3m2!1sno!2sno!4v1719667703479!5m2!1sno!2sno&zoom=5" 
                        height="100%"
                        width="100%"
                        loading="lazy">
                    </iframe>
                </div>           
            </div>
    
                <div className='rowEntry'>
                    <div className = "textBoxBlue">
                        <p>Priser:</p>

                        <p>NB! 6 måneder bindingstid!</p>

                        <p>Liten 3.5 kvadratmeter: kr 545,- mnd</p>
                        <p>Middels: 5 kvadratmeter kr 699,- mnd</p>
                        <p>Stor: 10 kvadratmeter   kr 1500,- mnd</p>
                    </div>

                    <div className = "textBoxBluePcOnly">
                        <p>Bodene står tørt og er tilgjengelige med trapp og heis.</p>  
                        <p>Bodene kan aksesseres med kodelås imellom 07:00 og 23:00 alle dager. </p>
                        <p>Hengelås må medbringes og bodene er ustyrt for dette.</p>
                    </div>
            </div>

            {/*}
            <div className='rowEntry'>
                {ImageGrid()}
            </div>
            {*/}


            <div className='rowEntry'>
                {ContactUs()}
            </div>

            <div className='rowEntry'>
                <div className="footer">
                    <h2>Kontaktinformasjon:</h2>
                    <p>holmestrandbodhotell@gmail.no</p>
                    <p>Bentsrudveien 18, 3080 Holmestrand</p>
                </div>        
            </div>
    </div>
    );

}


export default InformationGrid;